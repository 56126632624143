import { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  User,
} from "firebase/auth";
let isRequestingLogin = false;

interface IContext {
  user: User;
}

export const AuthContext = createContext<IContext>(undefined);

export const AuthProviderReal = ({ children }) => {
  const [user, setUser] = useState<User>();
  const auth = getAuth();
  useEffect(() => {
    return onAuthStateChanged(auth, (user: User) => {
      setUser(user);
    });
  });

  if (user === null && !isRequestingLogin) {
    isRequestingLogin = true;
    try {
      signInAnonymously(auth).finally(() => {
        isRequestingLogin = false;
      });
    } catch (error) {
      isRequestingLogin = false;
      console.error(error);
    }
  }
  const value = { user };
  if (!user) {
    return null;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthProviderMock = ({ children }) => {
  return (
    <AuthContext.Provider
      //@ts-ignore
      value={{ user: { isAnonymous: false, uid: "fakeid" } }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProvider =
  process.env.REACT_APP_PREVIEW_MODE === "true"
    ? AuthProviderMock
    : AuthProviderReal;

export const useAuth = () => useContext(AuthContext);
