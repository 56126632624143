import {
  CardModule,
  ContainerModule,
  PageModule,
  TextModule,
} from "../modules";

export const defaultStatusPageConfig = (image: string) => {
  return new PageModule({
    id: "status_page",
    modules: [
      new ContainerModule({
        id: "status_page_container",
        style: { width: "100%", maxWidth: "660px", marginBottom: 30 },
        modules: [
          new CardModule({
            id: "status_page_card",
            image: { align: "center", src: image },
            variant: "header",
            backgroundColor: "#FAFBFB",
            modules: [
              new ContainerModule({ modules: [], style: { height: 14 } }),
              new TextModule({
                id: "status_page_body",
                label: {
                  en: "Thank you for visiting this survey. Unfortunately, we are no longer collecting entries.",
                },
                variants: { desktop: "Body1", mobile: "Body1" },
              }),
            ],
          }),
        ],
      }),
    ],
  }).toSurveyConfig();
};
