import {
  Module,
  useDataContext,
  shuffleArray,
  QuestionContainer,
  useSurveyContext,
} from "Components";
import { useEffect } from "react";
import {
  SurveyConfigModule,
  DefaultProfilingCategory,
  ProfilingCategoryId,
} from "@max/common/setfan";

let shared = [];

export const Profiling = ({
  id,
  index = 0,
  style,
  header,
  options,
  visibility,
  validation,
  ...props
}: SurveyConfigModule) => {
  const {
    survey,
    id: surveyId,
    email,
    isEmailInjected,
    progressiveProfiling,
    loadingProfiling,
  } = useSurveyContext();
  const { data, setData } = useDataContext();

  useEffect(() => {
    if (!shared[index] && !loadingProfiling) {
      let submittedKeys = Object.entries(progressiveProfiling || {})?.reduce(
        (acc, [key, val]) => {
          if (key.startsWith("profilecategory")) {
            acc[key] = val;
          }
          return acc;
        },
        {},
      );
      //don't ask email if we already have it
      if (email) {
        submittedKeys["profilecategory.email"] = true;
      }

      //don't ask alcohol if they're under 21
      if (!data?.["profiling.ofAge"]?.value) {
        submittedKeys["profilecategory.alcohol"] = true;
      }

      let selectedCategory =
        props.force ||
        shared[index] ||
        data?.[`profiling.selectedCategory.${surveyId}`]?.value[index];
      const otherSelections = data?.[`profiling.selectedCategory.${surveyId}`]
        ?.value as string[];
      if (otherSelections) {
        otherSelections.forEach((k, idx) => {
          if (k && idx !== index) {
            shared[idx] = k;
          }
        });
      }
      shared.forEach((k, idx) => {
        if (idx !== index) {
          submittedKeys[k] = true;
        }
      });
      if (!selectedCategory) {
        survey?.localProfiling.find((priority) => {
          return shuffleArray(priority).find(
            (category: DefaultProfilingCategory) => {
              if (!submittedKeys[category.id]) {
                selectedCategory = category.id;
                return true;
              }
              return false;
            },
          );
        });
      }
      if (!selectedCategory) {
        selectedCategory = "profilecategory.completed"; //we asked everything
      }
      let newval = [...shared];
      newval.push(selectedCategory);
      shared = newval;
      const segments = shared.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: { value: true },
        }),
        {},
      );
      setData({
        ...data,
        [`profiling.selectedCategory.${surveyId}`]: {
          value: newval,
        },
        ...segments,
      });
    }
  }, [
    email,
    index,
    props.force,
    survey?.localProfiling,
    loadingProfiling,
    surveyId,
    progressiveProfiling,
  ]);

  const profileCategory = ((): DefaultProfilingCategory | undefined => {
    if (
      data[`profiling.selectedCategory.${surveyId}`]?.value[index] ===
      "profilecategory.completed"
    ) {
      return defaultCategory(surveyId);
    }

    // currently, we check this because the preview forces all categories to show profiling as we want
    if (props.force) {
      // we hide the email input field if a) the profiling block is a forced email and b) we have progressive profiling
      // for that email address. However, this should only happen if the email is passed to the survey externally
      // ie. through the url params or something similar. if we match while the user is entering their email address
      // in the survey, we do not want to hide the input (would be weird to have it just disappear from the screen).
      if (
        props.force === "profilecategory.email" &&
        progressiveProfiling?.email &&
        isEmailInjected
      ) {
        return undefined;
      } else {
        for (const categoryGroup of survey.localProfiling) {
          for (const category of categoryGroup) {
            if (category.id === props.force) {
              return category;
            }
          }
        }
        // could not find category in local profiling
        return undefined;
      }
    }

    let cat: DefaultProfilingCategory | undefined = undefined;

    survey?.localProfiling?.find((category) =>
      category.find((q) => {
        if (
          q.id === data[`profiling.selectedCategory.${surveyId}`]?.value[index]
        ) {
          cat = q;
          return true;
        }
        return false;
      }),
    );
    return cat;
  })();

  return (
    <>
      {profileCategory?.preface?.map((q) => {
        return (
          <QuestionContainer
            noBorder
            key={`profile-category-preface-${q.content.slice(0, 10)}`}
          >
            <Module {...q} />
          </QuestionContainer>
        );
      })}
      {profileCategory?.questions?.map((q) => {
        return (
          <Module
            {...{
              ...q,
              header: {
                children: q.header?.children?.replaceAll(
                  "{artist}",
                  survey.artistName,
                ),
              },
            }}
            key={`profile-category-question-${q.id}`}
          />
        );
      })}
    </>
  );
};

const defaultCategory = (surveyId: string): DefaultProfilingCategory => ({
  // this is a valid id but adding to our union would bring unneeded complexity to types
  id: "profilecategory.completed" as ProfilingCategoryId,
  questions: [
    {
      type: "TextQuestion",
      header: { children: "What would you like to tell us?" },
      id: `profilecategory.completed.${surveyId}`,
    },
  ],
});
