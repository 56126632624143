export const fr = {
  translation: {
    /* common */
    Cookies: "Cookies",
    Submit: "Soumettre",
    Continue: "Continuer",
    "Privacy Policy": "Politique de confidentialité",
    "Terms of Use": "Conditions d'utilisation",
    "This field is required": "Ce champ est obligatoire",
    "This question is required": "Cette question est obligatoire",
    "Please correct the missing or incomplete information above and hit submit again":
      "Corrige les informations manquantes ou incomplètes ci-dessus et clique à nouveau sur soumettre.",

    /* home page */
    "Go beyond hearts and likes for deeper fan connections":
      "Go beyond hearts and likes for deeper fan connections",
    "Gain the power to directly contact your fans to  announce upcoming tours, releases, or merch. Make sure every fan who wants to support the artist can.":
      "Gain the power to directly contact your fans to  announce upcoming tours, releases, or merch. Make sure every fan who wants to support the artist can.",
    "Give your fans a voice": "Give your fans a voice",
    "Involve fans in your process!  Our AI-assisted system helps you magically generate digital engagements that let fans be a part of your next tour, merch drop or single release.":
      "Involve fans in your process!  Our AI-assisted system helps you magically generate digital engagements that let fans be a part of your next tour, merch drop or single release.",
    "Know your fans as well as they know you":
      "Know your fans as well as they know you",
    "SET.Fan can help you discover your fans’ interests. This information is incredibly valuable when it comes to partnering with brand sponsors and making the most of your marketing efforts.":
      "SET.Fan can help you discover your fans’ interests. This information is incredibly valuable when it comes to partnering with brand sponsors and making the most of your marketing efforts.",
    "Connect directly with your fans": "Connect directly with your fans",
    "Don’t rely on algorithms to decide whether you can reach your fanbase. Use SET.Fan to know and speak with your biggest supporters":
      "Don’t rely on algorithms to decide whether you can reach your fanbase. Use SET.Fan to know and speak with your biggest supporters",
    "Start Connecting With Your Fans": "Start Connecting With Your Fans",
    "See how SET.Fan works": "See how SET.Fan works",
    "Part of the SET Suite": "Part of the SET Suite",
    "The team at SET is committed to empowering artists to directly connect with their fans around the world. The tools and services we offer help artists get their music heard, lift ticket and merch sales and know their fans like never before.":
      "The team at SET is committed to empowering artists to directly connect with their fans around the world. The tools and services we offer help artists get their music heard, lift ticket and merch sales and know their fans like never before.",
    "Learn About SET.Live": "Learn About SET.Live",

    /* profilecetegory.email */
    "Enter your Email": "Veuillez saisir Corriel",
    "Please enter your email": "Veuillez saisir votre Corriel",

    /* profilecetegory.core */
    "Please enter your name": "Veuillez saisir Prénom",
    "Your Phone": "Ton Téléphone",
    "Please enter your phone": "Veuillez saisir Téléphone",
    "Your country": "Ton Pays",
    "Please enter your country": "Veuillez saisir Pays",
    "Postal Code": "Code Postal",
    "Zip Code": "Code Postal",
    "Please enter your zip code": "Veuillez saisir Code Postal",

    /* profilecategory.demographics */
    // profiling.yearborn
    "What year were you born?": "Quelle année êtes-vous né(e)?",
    "Enter year as YYYY format (e.g. 1987)":
      "Entrez l'année au format AAAA (par exemple, 1987)",
    "Please enter your birth year": "Veuillez saisir année de naissance",

    // profiling.gender
    "Are you?": "Tu es?",
    Female: "Femme",
    Male: "Homme",
    "Non-binary": "Non binaire",
    "Prefer not to say": "Préfère ne pas répondre",

    // profiling.musicsource
    "How do you most often listen to music (Choose up to 3)?":
      "Comment écoutes-tu le plus souvent de la musique ? (Choisir jusqu’à 3)",
    Radio: "Radio",
    "Vinyl/Records": "Vinyles/Disques",
    Tapes: "Cassettes",
    CDs: "CDs",
    "Amazon Music Unlimited": "Amazon Music Unlimited",
    "Apple Music": "Apple Music",
    "Pandora (Free)": "Pandora (gratuit)",
    "Pandora (Paid Subscription)": "Pandora (abonnement)",
    "Spotify (Free)": "Spotify (gratuit)",
    "Spotify (Paid Subscription)": "Spotify (abonnement)",
    Tidal: "Tidal",
    "YouTube (The Main Website/App)": "Youtube (site web / application)",
    "YouTube (Music Streaming Service)": "Youtube Music (streaming)",

    // profiling.socialusage
    "Which of these do you use daily?":
      "Lesquelles de ces applications utilises-tu au quotidien ?",
    BeReal: "BeReal",
    Discord: "Discord",
    Facebook: "Facebook",
    Instagram: "Instagram",
    Snapchat: "Snapchat",
    TikTok: "TikTok",
    Twitch: "Twitch",
    Twitter: "Twitter",

    /* profilecategory.fooddrink */
    // profiling.eaten
    "Which of the following have you eaten in the last week?":
      "Dans lequel des établissements suivants as-tu mangé la semaine dernière?",
    "A meal from a casual sit-down restaurant (dine-in or takeout)":
      "Un repas dans un restaurant décontracté (sur place ou à emporter)",
    "A meal from a fine dining restaurant (dine-in or takeout)":
      "Un repas dans un restaurant gastronomique (sur place ou à emporter)",
    "Restaurant food delivered via a service like UberEats":
      "Repas d’un restaurant livré par un service comme UberEats",
    "A meal kit/service I bought":
      "Un kit de repas à préparer / service que j’ai acheté",
    "A meal from a fast food restaurant (dine-in, drive-through or takeout":
      "Un repas d'un restaurant de restauration rapide (sur place, en voiture ou à emporter)",

    // profiling.groceries
    "When you’re getting groceries which are important to you?":
      "Lorsque tu fais des courses, qu’est-ce-qui est important pour toi?",
    "What I’m craving": "Ce dont j'ai envie",
    "How healthy it is": "Ce qui est plus sain",
    "What's on sale/affordable": "Ce qui est en promotion",
    "What is local or organic": "Ce qui est local ou bio",
    "What can be delivered": "Ce qui peut être livré",

    // profiling.drinks
    "Which of these do you drink on a regular basis?":
      "Lesquelles de ces boissons bois-tu régulièrement?",
    "Diet soft drinks":
      "Boissons gazeuses diététiques / Boissons gazeuses light?",
    "Bottled/flavored water": "Eau en bouteille / aromatisée",
    Tea: "Thé",
    "Fruit or vegetable juice": "Jus de fruits ou de légumes",
    "Sports drinks": "Boissons sportives / énergétiques",
    "Soft drinks (non-diet)": "Boissons gazeuses (non diététiques)",
    "Coffee/coffee drinks": "Café / boissons au café",
    "Energy drinks": "Boissons énergisantes",
    "None of these": "Aucune de ces réponses",

    // profiling.foodpartner
    "What food/drink brand or restaurant do you think would be a good partner for an upcoming tour?":
      "D’après toi, quelle marque de nourriture/boisson ou restaurant serait un bon partenaire pour une prochaine tournée?",

    /* profilecategory.alcohol */
    // profiling.alcoholchoice
    "When you’re drinking, which of these do you tend to drink (check all that apply)?":
      "Cuando estás bebiendo, ¿cuál de los siguientes tiendes a beber? [marca todos los que apliquen]",
    "Please select at least one item":
      "Veuillez sélectionner au moins un élément",
    "Regular (not light, not craft/import) beer":
      "Bière (ni légère, ni artisanale / importée)",
    "Light beer": "Bière légère",
    "Imported or Craft Beer": "Bière importée ou artisanale",
    Wine: "Vin",
    "Vodka (or Vodka Mixed Drinks)": "Vodka (ou boissons á la vodka)",
    "Whiskey/Bourbon (or Whiskey/Bourbon Mixed Drinks)":
      "Whisky/Bourbon (ou boissons au whisky / bourbon)",
    "Rum (or Rum Mixed Drinks)": "Rhum (ou boissons au rhum)",
    "Gin (or Gin Mixed Drinks)": "Gin (ou boissons au gin)",
    "Tequila (or Tequila Mixed Drinks)": "Tequila (ou boissons á la tequila)",
    "A premium liquor": "Liqueur premium",
    "Budget/cheap liquor": "Liqueur bon marché",
    "Hard Cider or Lemonade":
      "Cidre ou boisson alcoolisée aromatisée au citron",
    "Hard Seltzer": "Boissons gazeuses alcoolisées",
    "I don’t drink alcohol": "Je ne bois pas d’alcool",

    // profiling.alcoholpartner
    "What beer, wine or alcohol brands could you see {artist} partnering with?":
      "D’après toi, quelle marque de bière/alcool serait un bon partenaire pour une prochaine tournée?",
    "Please tell us a partner brand":
      "Veuillez nous indiquer une marque partenaire",

    /* profilecategory.clothing */
    // profiling.buyclothing
    "Which of these do you regularly buy clothes/accessories from?":
      "Dans lequel de ces magasins achetez-vous régulièrement des vêtements/accessoires ?",
    "Discount clothing stores": "Magasins de vêtements à prix réduits",
    "Mainstream mall clothing stores":
      "Magasins de vêtements grand public dans les centres commerciaux",
    "High end fashion stores": "Magasins de mode haut de gamme",
    "The online versions of mainstream mall stores":
      "Les versions en ligne des grands magasins des centres commerciaux",
    "A store like WalMart or Target": "Un magasin comme Walmart ou Target",
    "Online-only stores": "Magasins en ligne uniquement",
    "Online subscription services": "Services d'abonnement en ligne",

    // profiling.artistclothing
    "What clothing, accessories or cosmetics brands would be a good fit for {artist}? This could be any brands you love or ones you associate with {artist}":
      "Quelles marques de vêtements, accessoires ou cosmétiques seraient bien adaptées pour {artist} ? Cela pourrait être n'importe quelles marques que vous aimez ou celles que vous associez à {artist}",
    "Please enter a response": "Veuillez entrer une réponse",

    // profiling.beauty
    "Which of these are true for you in deciding on personal care and beauty products (like cosmetics, fragrances/cologne, teeth whitening, hair styling, shaving)?":
      "Lesquelles de ces affirmations sont pertinentes pour toi dans le choix des produits de soins personnels et de beauté (comme les cosmétiques, les parfums, le blanchiment des dents, la coiffure, le rasage)?",
    "I usually look for the most economical option":
      "Je recherche généralement l'option la plus abordable",
    "I prefer to buy products from premium brands":
      "Je préfère acheter des produits de marques premium",
    "The company’s values are an important factor in my decision":
      "Les valeurs de l’entreprise sont un facteur important dans ma décision",
    "I prefer cosmetics/personal care products that are natural or organic":
      "Je préfère les cosmétiques / produits de soins personnels naturels ou biologiques",
    "I use a subscription service (like Dollar Shave Club or Birchbox)":
      "J'utilise un service d'abonnement",

    /* profilecategory.vehicle */
    // profiling.nextvehicle
    "Which types of vehicle would you consider getting next?":
      "Quel type de véhicule envisagerais-tu acheter?",
    Electric: "Eléctrique",
    Hybrid: "Hybride",
    SUV: "SUV",
    "Pickup Truck": "Pick-up",
    "Small Car": "Voiture compact",
    Hatchback: "Hatchback",
    Motorcycle: "Moto",

    // profiling.nextvehiclecost
    "Which of these are likely to describe your next vehicle?":
      "Parmi les catégories suivantes, lesquelles sont susceptibles de décrire ton prochain véhicule?",
    "Budget/inexpensive": "Budget / bon marché",
    "Full-Featured/High-End Trim": "Haut de gamme / de luxe",
    Used: "D’occasion",
    New: "Nouveau",
    American: "Américain",
    Import: "Importé",
    "I don’t drive": "Je ne conduis pas",

    // profiling.vehiclepartner
    "What car brand would make a good tour partner?":
      "Quelle marque de voitures ferait un bon partenaire de tournée?",
    Acura: "Acura",
    Alfa: "Alfa",
    "Aston Martin": "Aston Martin",
    Audi: "Audi",
    Bentley: "Bentley",
    BMW: "BMW",
    Buick: "Buick",
    Cadillac: "Cadillac",
    "Chevy/Chevrolet": "Chevy/Chevrolet",
    Chrysler: "Chrysler",
    Dodge: "Dodge",
    Ferrari: "Ferrari",
    FIAT: "FIAT",
    Ford: "Ford",
    Genesis: "Genesis",
    GMC: "GMC",
    Honda: "Honda",
    HUMMER: "HUMMER",
    Hyundai: "Hyundai",
    INFINITI: "INFINITI",
    Isuzu: "Isuzu",
    Jaguar: "Jaguar",
    Jeep: "Jeep",
    Kia: "Kia",
    Lamborghini: "Lamborghini",
    "Land Rover": "Land Rover",
    Lexus: "Lexus",
    Lincoln: "Lincoln",
    Maserati: "Maserati",
    MAZDA: "MAZDA",
    "Mercedes-Benz": "Mercedes-Benz",
    Mercury: "Mercury",
    MINI: "MINI",
    Mitsubishi: "Mitsubishi",
    NISSAN: "NISSAN",
    Porsche: "Porsche",
    RAM: "RAM",
    "Rolls-Royce": "Rolls-Royce",
    Saab: "Saab",
    Saturn: "Saturn",
    Scion: "Scion",
    smart: "smart",
    Subaru: "Subaru",
    Suzuki: "Suzuki",
    Tesla: "Tesla",
    Toyota: "Toyota",
    Volkswagen: "Volkswagen",
    Volvo: "Volvo",

    /* profilecategory.entertainment */
    // profiling.sparetime
    "Which of these are things you like to do in your spare time?":
      "Lesquelles parmi ces activités suivantes aimes-tu faire pendant ton temps libre?",
    "Playing team sports (basketball/soccer/softball, etc.)":
      "Pratiquer un sport collectif (basket, football, softball etc.)",
    "Going to a bar": "Aller dans un bar",
    "Checking social media": "Regarder les réseaux sociaux",
    Golf: "Golf",
    "Listening to music": "Écouter de la musique",
    "Playing or creating music (guitar, piano, sequencing, etc.)":
      "Jouer ou créer de la musique (guitare, piano, séquençage, etc.)",
    "Gaming (console or computer)": "Jeux vidéo (console ou ordinateur)",
    "Camping or Fishing": "Camping ou pêche",
    "Crafts/Sewing/crocheting": "Artisanat / couture / crochet",
    Astrology: "Astrologie",
    "Volunteer/charity work": "Travail bénévole / caritatif",
    "Creating social media content":
      "Création de contenu sur les réseaux sociaux",
    Reading: "Lecture",
    "Work on my car/car tuning": "Bricoler ma voiture / personnalisation",
    "Off-roading/overlanding": "Conduite hors piste",
    Traveling: "Voyager",
    "Home improvement": "Amélioration du logement",
    Yoga: "Yoga",
    "Cooking/baking": "Cuisiner / faire de la pâtisserie",
    "Church/church activities": "Religion / activités religieuses",
    Biking: "Cyclisme",
    "Going to a concert": "Aller à un concert",
    "Watching TV/movies/streaming content at home":
      "Regarder la télévision / des films / streaming à la maison",
    "Going to the movies": "Aller au cinéma",
    "Running/doing cardio workouts": "Courir/faire des entraînements cardio",
    "Lifting weights": "Soulever des poids",
    "Listen to podcasts": "Écoutez des podcasts",
    Hiking: "Randonnées",
    "Watch sports": "Regarder du sport",
    Photography: "Photographie",
    "None of the above": "Aucune de ces réponses",

    // profiling.hobbybrands
    "What brands related to your hobbies do you think would be a good sponsor for {artist}? Please share any favorite stores or brands that you think would be a fit!":
      "Quelles marques liées à vos hobbies pensez-vous qu'un bon sponsor pour {artist} ? Veuillez partager tous les magasins ou marques favoris que vous pensez être adaptés!",
    "Please provide a response": "Veuillez fournir une réponse",

    // profiling.watchproviders
    "You sit down to watch something, which are you likely to be watching?":
      "Si tu regardes quelque chose, qu’est-ce qui serait le plus probable pour toi?",
    "Traditional TV/cable": "Télévision traditionnelle / télévision par câble",
    "Streaming Services":
      "Services de streaming (comme Netflix / Amazon Prime / Disney+)",
    YouTube: "YouTube",
    "Short Form Video": "Vidéos courtes (TikTok ou Instagram Reels)",
    "Twitch or live video platform":
      "Twitch ou plateforme vidéo en direct (IG Live / Facebook Live)",

    // profiling.watchtech
    "Which of the following do you have?":
      "Lequel de ces appareils possèdes-tu?",
    "A piece of “smart” gym equipment": 'Équipement de gym "intelligent"',
    "A crypto wallet": "Un portefeuille crypto",
    "A VR headset": "Casque de réalité virtuelle",
    "“Wearable” Tech":
      "Technologie «portable» (comme une montre connectée ou un traqueur d'activité)",
    "“Smart Home” system":
      "Système domotique maison (comme des ampoules connectées, des capteurs) / détecteurs",
    "Smart Speaker": "Enceinte connectée (comme un Echo Dot ou Google Home)",
    "Newest generation console":
      "Console de dernière génération (Xbox Series X / Nintendo Switch / PS5)",
  },
};
