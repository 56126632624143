export const es = {
  translation: {
    /* common */
    Cookies: "Cookies",
    Submit: "Enviar",
    Continue: "Continuar",
    "Privacy Policy": "Política de privacidad",
    "Terms of Use": "Términos de uso",
    "This field is required": "Este campo se requiere",
    "This question is required": "Esta pregunta es obligatoria",
    "Please correct the missing or incomplete information above and hit submit again":
      "Por favor, corrige la información que falta o está incompleta y pulsa enviar de nuevo",

    /* home page */
    "Go beyond hearts and likes for deeper fan connections":
      "Go beyond hearts and likes for deeper fan connections",
    "Gain the power to directly contact your fans to  announce upcoming tours, releases, or merch. Make sure every fan who wants to support the artist can.":
      "Gain the power to directly contact your fans to  announce upcoming tours, releases, or merch. Make sure every fan who wants to support the artist can.",
    "Give your fans a voice": "Give your fans a voice",
    "Involve fans in your process!  Our AI-assisted system helps you magically generate digital engagements that let fans be a part of your next tour, merch drop or single release.":
      "Involve fans in your process!  Our AI-assisted system helps you magically generate digital engagements that let fans be a part of your next tour, merch drop or single release.",
    "Know your fans as well as they know you":
      "Know your fans as well as they know you",
    "SET.Fan can help you discover your fans’ interests. This information is incredibly valuable when it comes to partnering with brand sponsors and making the most of your marketing efforts.":
      "SET.Fan can help you discover your fans’ interests. This information is incredibly valuable when it comes to partnering with brand sponsors and making the most of your marketing efforts.",
    "Connect directly with your fans": "Connect directly with your fans",
    "Don’t rely on algorithms to decide whether you can reach your fanbase. Use SET.Fan to know and speak with your biggest supporters":
      "Don’t rely on algorithms to decide whether you can reach your fanbase. Use SET.Fan to know and speak with your biggest supporters",
    "Start Connecting With Your Fans": "Start Connecting With Your Fans",
    "See how SET.Fan works": "See how SET.Fan works",
    "Part of the SET Suite": "Part of the SET Suite",
    "The team at SET is committed to empowering artists to directly connect with their fans around the world. The tools and services we offer help artists get their music heard, lift ticket and merch sales and know their fans like never before.":
      "The team at SET is committed to empowering artists to directly connect with their fans around the world. The tools and services we offer help artists get their music heard, lift ticket and merch sales and know their fans like never before.",
    "Learn About SET.Live": "Learn About SET.Live",

    /* profilecetegory.email */
    "Enter your Email": "Ingresa tu Correo Electrónico",
    "Please enter your email": "Por favor, ingresa tu Correo Electrónico",

    /* profilecetegory.core */
    "Please enter your name": "Por favor, ingresa tu nombre",
    "Your Phone": "Tu Teléfono",
    "Please enter your phone": "Por favor, ingresa tu Teléfono",
    "Your country": "Tu Pais",
    "Please enter your country": "Por favor, ingresa tu pais",
    "Please enter your zip code": "Por favor, ingresa tu Código Postal",
    "Postal Code": "Código Postal",
    "Zip Code": "Código Postal",

    /* profilecategory.demographics */
    // profiling.yearborn
    "What year were you born?": "¿En qué año naciste?",
    "Enter year as YYYY format (e.g. 1987)":
      "Ingresa el año en formato AAAA (por ejemplo, 1987)",
    "Please enter your birth year": "Por favor ingresa tu año de nacimiento",

    // profiling.gender
    "Are you?": "¿Eres?",
    Female: "Mujer",
    Male: "Hombre",
    "Non-binary": "Non binario",
    "Prefer not to say": "Prefiero no decirlo",

    // profiling.musicsource
    "How do you most often listen to music (Choose up to 3)?":
      "¿Cómo escuchas música con mayor frecuencia? (Elige hasta 3)",
    Radio: "Radio",
    "Vinyl/Records": "Vinilos/Discos",
    Tapes: "Cassettes",
    CDs: "CDs",
    "Amazon Music Unlimited": "Amazon Music Unlimited",
    "Apple Music": "Apple Music",
    "Pandora (Free)": "Pandora (gratis)",
    "Pandora (Paid Subscription)": "Pandora (suscripción pagada)",
    "Spotify (Free)": "Spotify (gratis)",
    "Spotify (Paid Subscription)": "Spotify (suscripción pagada)",
    Tidal: "Tidal",
    "YouTube (The Main Website/App)":
      "YouTube (sitio web principal / aplicación)",
    "YouTube (Music Streaming Service)":
      "Youtube Music (servicio de música por streaming)",

    // profiling.socialusage
    "Which of these do you use daily?":
      "¿Cuáles de estas aplicaciones usas diariamente?",
    BeReal: "BeReal",
    Discord: "Discord",
    Facebook: "Facebook",
    Instagram: "Instagram",
    Snapchat: "Snapchat",
    TikTok: "TikTok",
    Twitch: "Twitch",
    Twitter: "Twitter",

    /* profilecategory.fooddrink */
    // profiling.eaten
    "Which of the following have you eaten in the last week?":
      "¿Cuáles de los siguientes lugares has consumido en la última semana?",
    "A meal from a casual sit-down restaurant (dine-in or takeout)":
      "Una comida en un restaurante casual (en el lugar o para llevar)",
    "A meal from a fine dining restaurant (dine-in or takeout)":
      "Una comida de un restaurante de alta cocina (en el lugar o para llevar)",
    "Restaurant food delivered via a service like UberEats":
      "Comida de restaurante a domicilio a través de servicios como UberEats",
    "A meal kit/service I bought":
      "Un kit de comida preparada / servicio que compré",
    "A meal from a fast food restaurant (dine-in, drive-through or takeout":
      "Una comida de un restaurante de comida rápida (para comer allí, autoservicio o para llevar)",

    // profiling.groceries
    "When you’re getting groceries which are important to you?":
      "Cuando compras comida, ¿qué es importante para ti?",
    "What I’m craving": "Lo que estoy deseando",
    "How healthy it is": "Qué tan saludable es",
    "What's on sale/affordable": "Lo que está en oferta / accesible",
    "What is local or organic": "Lo que es local u orgánico",
    "What can be delivered": "Lo que pueda ser entregado a domicilio",

    // profiling.drinks
    "Which of these do you drink on a regular basis?":
      "¿Cuál de estas bebidas consumes regularmente?",
    "Diet soft drinks": "Sodas dietéticas",
    "Bottled/flavored water": "Agua embotellada / saborizada",
    Tea: "Té",
    "Fruit or vegetable juice": "Jugo de frutas o vegetales",
    "Sports drinks": "Bebidas deportivas / rehidratantes",
    "Soft drinks (non-diet)": "Bebidas sin alcohol (no-dietéticas)",
    "Coffee/coffee drinks": "Café / bebidas con café",
    "Energy drinks": "Bebidas energéticas",
    "None of these": "Ninguno de estos",

    // profiling.foodpartner
    "What food/drink brand or restaurant do you think would be a good partner for an upcoming tour?":
      "¿Cuáles marcas de comida/bebida o restaurante crees que serían un buen aliado para una gira?",

    /* profilecategory.alcohol */
    // profiling.alcoholchoice
    "When you’re drinking, which of these do you tend to drink (check all that apply)?":
      "Cuando estás bebiendo, ¿cuál de los siguientes tiendes a beber? [marca todos los que apliquen]",
    "Please select at least one item": "Por favor, selecciona al menos un ítem",
    "Regular (not light, not craft/import) beer":
      "Cerveza regular (no light, ni artesanal / importada)",
    "Light beer": "Cerveza light / baja en calorías",
    "Imported or Craft Beer": "Cerveza importada o artesanal",
    Wine: "Vino",
    "Vodka (or Vodka Mixed Drinks)": "Vodka (o bebidas con vodka)",
    "Whiskey/Bourbon (or Whiskey/Bourbon Mixed Drinks)":
      "Whisky/Bourbon (o bebidas con whisky / bourbon)",
    "Rum (or Rum Mixed Drinks)": "Ron (o bebidas con ron)",
    "Gin (or Gin Mixed Drinks)": "Ginebra (o bebidas con ginebra)",
    "Tequila (or Tequila Mixed Drinks)": "Tequila (o bebidas con tequila)",
    "A premium liquor": "Licor premium",
    "Budget/cheap liquor": "Licor de bajo costo / de bajo presupuesto",
    "Hard Cider or Lemonade": "Sidra o bebida alcohólica con sabor a limón",
    "Hard Seltzer": "Bebidas alcohólicas carbonatadas",
    "I don’t drink alcohol": "No bebo alcohol",

    // profiling.alcoholpartner
    "What beer, wine or alcohol brands could you see {artist} partnering with?":
      "¿Qué marca de cerveza o bebida alcohólica crees que sería un buen aliado para una gira?",
    "Please tell us a partner brand": "Por favor, indícanos una marca asociada",

    /* profilecategory.clothing */
    // profiling.buyclothing
    "Which of these do you regularly buy clothes/accessories from?":
      "¿En cuál de estos lugares compras regularmente ropa / accesorios?",
    "Discount clothing stores": "Tiendas de ropa en descuento",
    "Mainstream mall clothing stores": "Tiendas de ropa de centros comerciales",
    "High end fashion stores": "Tiendas de moda de alta gama",
    "The online versions of mainstream mall stores":
      "La versión en línea de tiendas de centro comercial",
    "A store like WalMart or Target": "Tiendas como Walmart o Target",
    "Online-only stores": "Tiendas sólo en línea",
    "Online subscription services": "Servicios de suscripción en línea",

    // profiling.artistclothing
    "What clothing, accessories or cosmetics brands would be a good fit for {artist}? This could be any brands you love or ones you associate with {artist}":
      "¿Qué marcas de ropa, accesorios o cosméticos serían adecuadas para {artist}? Podrían ser marcas que te encanten o que asocies con {artist}?",
    "Please enter a response": "Por favor, introduce una respuesta",

    // profiling.beauty
    "Which of these are true for you in deciding on personal care and beauty products (like cosmetics, fragrances/cologne, teeth whitening, hair styling, shaving)?":
      "¿Cuál de estos es importante para ti al decidir sobre productos de belleza y cuidado personal (como cosméticos, fragancias / colonias, blanqueamiento dental, peluquería, afeitado)?",
    "I usually look for the most economical option":
      "Usualmente busco la opción más económica",
    "I prefer to buy products from premium brands":
      "Prefiero comprar productos de marcas de primera calidad",
    "The company’s values are an important factor in my decision":
      "Los valores de la empresa son un factor importante en mi decisión",
    "I prefer cosmetics/personal care products that are natural or organic":
      "Prefiero productos cosméticos / cuidado personal que sean naturales u orgánicos",
    "I use a subscription service (like Dollar Shave Club or Birchbox)":
      "Uso un servicio por subscripción",

    /* profilecategory.vehicle */
    // profiling.nextvehicle
    "Which types of vehicle would you consider getting next?":
      "¿Qué tipos de vehículo considerarías comprar?",
    Electric: "Eléctrico",
    Hybrid: "Híbrido",
    SUV: "Todoterreno",
    "Pickup Truck": "Pick-up / Camioneta",
    "Small Car": "Auto pequeño",
    Hatchback: "Hatchback",
    Motorcycle: "Motocicleta",

    // profiling.nextvehiclecost
    "Which of these are likely to describe your next vehicle?":
      "¿Cuál de los siguientes describe mejor tu próximo vehículo?",
    "Budget/inexpensive": "Bajo presupuesto / barato",
    "Full-Featured/High-End Trim": "Equipo completo / acabados de lujo",
    Used: "Usado",
    New: "Nuevo",
    American: "Americano",
    Import: "Importado",
    "I don’t drive": "No manejo",

    // profiling.vehiclepartner
    "What car brand would make a good tour partner?":
      "¿Qué marca de vehículo sería un buen aliado para una gira?",
    Acura: "Acura",
    Alfa: "Alfa",
    "Aston Martin": "Aston Martin",
    Audi: "Audi",
    Bentley: "Bentley",
    BMW: "BMW",
    Buick: "Buick",
    Cadillac: "Cadillac",
    "Chevy/Chevrolet": "Chevy/Chevrolet",
    Chrysler: "Chrysler",
    Dodge: "Dodge",
    Ferrari: "Ferrari",
    FIAT: "FIAT",
    Ford: "Ford",
    Genesis: "Genesis",
    GMC: "GMC",
    Honda: "Honda",
    HUMMER: "HUMMER",
    Hyundai: "Hyundai",
    INFINITI: "INFINITI",
    Isuzu: "Isuzu",
    Jaguar: "Jaguar",
    Jeep: "Jeep",
    Kia: "Kia",
    Lamborghini: "Lamborghini",
    "Land Rover": "Land Rover",
    Lexus: "Lexus",
    Lincoln: "Lincoln",
    Maserati: "Maserati",
    MAZDA: "MAZDA",
    "Mercedes-Benz": "Mercedes-Benz",
    Mercury: "Mercury",
    MINI: "MINI",
    Mitsubishi: "Mitsubishi",
    NISSAN: "NISSAN",
    Porsche: "Porsche",
    RAM: "RAM",
    "Rolls-Royce": "Rolls-Royce",
    Saab: "Saab",
    Saturn: "Saturn",
    Scion: "Scion",
    smart: "smart",
    Subaru: "Subaru",
    Suzuki: "Suzuki",
    Tesla: "Tesla",
    Toyota: "Toyota",
    Volkswagen: "Volkswagen",
    Volvo: "Volvo",

    /* profilecategory.entertainment */
    // profiling.sparetime
    "Which of these are things you like to do in your spare time?":
      "¿Cuáles de estas son cosas que te gustan hacer en tu tiempo libre?",
    "Playing team sports (basketball/soccer/softball, etc.)":
      "Jugar deportes de equipo (baloncesto / fútbol / softbol, etc.)",
    "Going to a bar": "Ir a un bar",
    "Checking social media": "Revisar redes sociales",
    Golf: "Golf",
    "Listening to music": "Escuchar música",
    "Playing or creating music (guitar, piano, sequencing, etc.)":
      "Tocar o crear música (guitarra, piano, secuenciación, etc.)",
    "Gaming (console or computer)": "Videojuegos (consola o computadora)",
    "Camping or Fishing": "Acampar o Pescar",
    "Crafts/Sewing/crocheting": "Manualidades / costura / tejer",
    Astrology: "Astrología",
    "Volunteer/charity work": "Voluntariado / trabajo de caridad",
    "Creating social media content": "Crear contenido en redes sociales",
    Reading: "Leer",
    "Work on my car/car tuning":
      "Trabajar en mi vehículo / modificar el vehículo",
    "Off-roading/overlanding": "Conducción todoterreno",
    Traveling: "Viajar",
    "Home improvement": "Remodelar el hogar",
    Yoga: "Yoga",
    "Cooking/baking": "Cocinar / hornear",
    "Church/church activities": "Iglesia / actividades de la iglesia",
    Biking: "Andar en bicicleta",
    "Going to a concert": "Ir a un concierto",
    "Watching TV/movies/streaming content at home":
      "Ver televisión / películas / contenido de streaming en casa",
    "Going to the movies": "Ir al cine",
    "Running/doing cardio workouts": "Correr/hacer ejercicios de cardio",
    "Lifting weights": "Levantar pesas",
    "Listen to podcasts": "Escuchar podcasts",
    Hiking: "Hacer senderismo",
    "Watch sports": "Mirar deportes",
    Photography: "Fotografía",
    "None of the above": "Ninguna de las anteriores",

    // profiling.hobbybrands
    "What brands related to your hobbies do you think would be a good sponsor for {artist}? Please share any favorite stores or brands that you think would be a fit!":
      "¿Qué marcas relacionadas con tus aficiones crees que serían un buen patrocinador para {artist}? ¡Por favor, comparte cualquier tienda o marca favorita que creas que encajaría!",
    "Please provide a response": "Por favor, proporciona una respuesta",

    // profiling.watchproviders
    "You sit down to watch something, which are you likely to be watching?":
      "Si te sientas a ver algo, ¿qué es lo más probable que veas?",
    "Traditional TV/cable": "Televisión tradicional / cable",
    "Streaming Services":
      "Servicios de Streaming (como Netflix / Amazon Prime / Disney+)",
    YouTube: "YouTube",
    "Short Form Video": "Videos Cortos (TikTok o Reels de instagram)",
    "Twitch or live video platform":
      "Twitch o plataforma de videos en vivo (IG Live / Facebook Live)",

    // profiling.watchtech
    "Which of the following do you have?": "¿Cuáles de los siguientes tienes?",
    "A piece of “smart” gym equipment": "Equipo de gimnasio “inteligente”",
    "A crypto wallet": "Una billetera electrónica",
    "A VR headset": "Un visor de realidad virtual",
    "“Wearable” Tech":
      "Tecnología “portable” (como reloj inteligente o rastreador fitness)",
    "“Smart Home” system":
      "Sistema “Smart Home” (cómo bombillas conectadas, sensores)",
    "Smart Speaker": "Bocinas inteligentes (como Echo Dot o Google Home)",
    "Newest generation console":
      "Consola de última generación (Xbox serie X / Nintendo Switch / PS5)",
  },
};
