import { useIsMobile } from "melodies-source/utils/hooks";
import styled from "styled-components";
import { Caption } from "./Modules";
import SetLiveLogo from "./SVGs/SetLiveLogo";
import { useThemeMode } from "../color";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    Cookiebot: {
      renew: () => void;
    };
  }
}

export const Footer = ({
  bordered = true,
  setModal,
}: {
  bordered?: boolean;
  setModal: (v: string) => void;
}) => {
  const isMobile = useIsMobile();
  const year = new Date().getFullYear();

  const { fontColor, useGradient } = useThemeMode();

  return isMobile ? (
    <FullWidth useGradient={useGradient}>
      <FooterContainer bordered={bordered} fontColor={fontColor}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 12,
          }}
        >
          <Logo />
          <CopyrightText year={year} />
        </div>
        <Links setModal={setModal} />
      </FooterContainer>
    </FullWidth>
  ) : (
    <FullWidth useGradient={useGradient}>
      <FooterContainer bordered={bordered} fontColor={fontColor}>
        <Logo />
        <Links setModal={setModal} />
        <CopyrightText year={year} />
      </FooterContainer>
    </FullWidth>
  );
};

const CopyrightText = ({ year }: { year: number }) => (
  <Caption>&copy; {year} Set.Live</Caption>
);

const Links = ({ setModal }: { setModal: (v: string) => void }) => {
  const { t } = useTranslation();
  return (
    <LinkContainer>
      <Link onClick={() => setModal("privacy")}>{t("Privacy Policy")}</Link>
      <Link onClick={() => setModal("terms")}>{t("Terms of Use")}</Link>
      <Link onClick={() => window.Cookiebot?.renew()}>{t("Cookies")}</Link>
    </LinkContainer>
  );
};

const FooterContainer = styled.div<{ bordered?: boolean; fontColor: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid
    ${({ bordered, fontColor }) => (bordered ? fontColor : "transparent")};
  padding: 20px 0 36px 0;
  margin: 0 33px;

  & > * {
    color: ${({ fontColor }) => fontColor};
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    margin: 0 21px;
  }
`;

const FullWidth = styled.div<{ useGradient: boolean }>`
  ${({ useGradient }) =>
    useGradient &&
    "background: linear-gradient(0deg,rgba(0, 0, 0, 0.75) 0%,rgba(0, 0, 0, 0) 100%);"}
  margin-left: -33px;
  margin-right: -33px;
  margin-top: auto;
  position: relative;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-left: -21px;
    margin-right: -21px;
  }
`;

const LinkContainer = styled.div`
  display: flex;
`;

const Logo = styled(SetLiveLogo)`
  width: 53px;
  height: 27px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 38px;
    height: 19px;
  }
`;

const FooterText = styled.p`
  font-size: 12px;
  line-height: 18px;
`;

const Link = styled(FooterText).attrs({ as: "a" })`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  & + & {
    margin-left: 12px;
  }
`;
