export const en = {
  translation: {
    /* common */
    Cookies: "Cookies",
    Submit: "Submit",
    Continue: "Continue",
    "Privacy Policy": "Privacy Policy",
    "Terms of Use": "Terms of Use",
    "This field is required": "This field is required",
    "Please enter your birth year": "Please enter your birth year",
    "This question is required": "This question is required",
    "Please correct the missing or incomplete information above and hit submit again":
      "Please correct the missing or incomplete information above and hit submit again",

    /* home page */
    "Go beyond hearts and likes for deeper fan connections":
      "Go beyond hearts and likes for deeper fan connections",
    "Gain the power to directly contact your fans to  announce upcoming tours, releases, or merch. Make sure every fan who wants to support the artist can.":
      "Gain the power to directly contact your fans to  announce upcoming tours, releases, or merch. Make sure every fan who wants to support the artist can.",
    "Give your fans a voice": "Give your fans a voice",
    "Involve fans in your process!  Our AI-assisted system helps you magically generate digital engagements that let fans be a part of your next tour, merch drop or single release.":
      "Involve fans in your process!  Our AI-assisted system helps you magically generate digital engagements that let fans be a part of your next tour, merch drop or single release.",
    "Know your fans as well as they know you":
      "Know your fans as well as they know you",
    "SET.Fan can help you discover your fans’ interests. This information is incredibly valuable when it comes to partnering with brand sponsors and making the most of your marketing efforts.":
      "SET.Fan can help you discover your fans’ interests. This information is incredibly valuable when it comes to partnering with brand sponsors and making the most of your marketing efforts.",
    "Connect directly with your fans": "Connect directly with your fans",
    "Don’t rely on algorithms to decide whether you can reach your fanbase. Use SET.Fan to know and speak with your biggest supporters":
      "Don’t rely on algorithms to decide whether you can reach your fanbase. Use SET.Fan to know and speak with your biggest supporters",
    "Start Connecting With Your Fans": "Start Connecting With Your Fans",
    "See how SET.Fan works": "See how SET.Fan works",
    "Part of the SET Suite": "Part of the SET Suite",
    "The team at SET is committed to empowering artists to directly connect with their fans around the world. The tools and services we offer help artists get their music heard, lift ticket and merch sales and know their fans like never before.":
      "The team at SET is committed to empowering artists to directly connect with their fans around the world. The tools and services we offer help artists get their music heard, lift ticket and merch sales and know their fans like never before.",
    "Learn About SET.Live": "Learn About SET.Live",

    /* profilecetegory.email */
    "Enter your Email": "Enter your Email",
    "Please enter your email": "Please enter your email",

    /* profilecetegory.core */
    "Please enter your name": "Please enter your name",
    "Your Phone": "Your Phone",
    "Please enter your phone": "Please enter your phone",
    "Your country": "Your country",
    "Please enter your country": "Please enter your country",
    "Please enter your zip code": "Please enter your zip code",
    "Postal Code": "Postal Code",
    "Zip Code": "Zip Code",

    /* profilecategory.demographics */
    // profiling.yearborn
    "What year were you born?": "What year were you born?",
    "Enter year as YYYY format (e.g. 1987)":
      "Enter year as YYYY format (e.g. 1987)",

    // profiling.gender
    "Are you?": "Are you?",
    Female: "Female",
    Male: "Male",
    "Non-binary": "Non-binary",
    "Prefer not to say": "Prefer not to say",

    // profiling.musicsource
    "How do you most often listen to music (Choose up to 3)?":
      "How do you most often listen to music (Choose up to 3)?",
    Radio: "Radio",
    "Vinyl/Records": "Vinyl/Records",
    Tapes: "Tapes",
    CDs: "CDs",
    "Amazon Music Unlimited": "Amazon Music Unlimited",
    "Apple Music": "Apple Music",
    "Pandora (Free)": "Pandora (Free)",
    "Pandora (Paid Subscription)": "Pandora (Paid Subscription)",
    "Spotify (Free)": "Spotify (Free)",
    "Spotify (Paid Subscription)": "Spotify (Paid Subscription)",
    Tidal: "Tidal",
    "YouTube (The Main Website/App)": "YouTube (The Main Website/App)",
    "YouTube (Music Streaming Service)": "YouTube (Music Streaming Service)",

    // profiling.socialusage
    "Which of these do you use daily?": "Which of these do you use daily?",
    BeReal: "BeReal",
    Discord: "Discord",
    Facebook: "Facebook",
    Instagram: "Instagram",
    Snapchat: "Snapchat",
    TikTok: "TikTok",
    Twitch: "Twitch",
    Twitter: "Twitter",

    /* profilecategory.fooddrink */
    // profiling.eaten
    "Which of the following have you eaten in the last week?":
      "Which of the following have you eaten in the last week?",
    "A meal from a casual sit-down restaurant (dine-in or takeout)":
      "A meal from a casual sit-down restaurant (dine-in or takeout)",
    "A meal from a fine dining restaurant (dine-in or takeout)":
      "A meal from a fine dining restaurant (dine-in or takeout)",
    "Restaurant food delivered via a service like UberEats":
      "Restaurant food delivered via a service like UberEats",
    "A meal kit/service I bought": "A meal kit/service I bought",
    "A meal from a fast food restaurant (dine-in, drive-through or takeout":
      "A meal from a fast food restaurant (dine-in, drive-through or takeout",

    // profiling.groceries
    "When you’re getting groceries which are important to you?":
      "When you’re getting groceries which are important to you?",
    "What I’m craving": "What I’m craving",
    "How healthy it is": "How healthy it is",
    "What's on sale/affordable": "What's on sale/affordable",
    "What is local or organic": "What is local or organic",
    "What can be delivered": "What can be delivered",

    // profiling.drinks
    "Which of these do you drink on a regular basis?":
      "Which of these do you drink on a regular basis?",
    "Diet soft drinks": "Diet soft drinks",
    "Bottled/flavored water": "Bottled/flavored water",
    Tea: "Tea",
    "Fruit or vegetable juice": "Fruit or vegetable juice",
    "Sports drinks": "Sports drinks",
    "Soft drinks (non-diet)": "Soft drinks (non-diet)",
    "Coffee/coffee drinks": "Coffee/coffee drinks",
    "Energy drinks": "Energy drinks",
    "None of these": "None of these",

    // profiling.foodpartner
    "What food/drink brand or restaurant do you think would be a good partner for an upcoming tour?":
      "What food/drink brand or restaurant do you think would be a good partner for an upcoming tour?",

    /* profilecategory.alcohol */
    // profiling.alcoholchoice
    "When you’re drinking, which of these do you tend to drink (check all that apply)?":
      "When you’re drinking, which of these do you tend to drink (check all that apply)?",
    "Please select at least one item": "Please select at least one item",
    "Regular (not light, not craft/import) beer":
      "Regular (not light, not craft/import) beer",
    "Light beer": "Light beer",
    "Imported or Craft Beer": "Imported or Craft Beer",
    Wine: "Wine",
    "Vodka (or Vodka Mixed Drinks)": "Vodka (or Vodka Mixed Drinks)",
    "Whiskey/Bourbon (or Whiskey/Bourbon Mixed Drinks)":
      "Whiskey/Bourbon (or Whiskey/Bourbon Mixed Drinks)",
    "Rum (or Rum Mixed Drinks)": "Rum (or Rum Mixed Drinks)",
    "Gin (or Gin Mixed Drinks)": "Gin (or Gin Mixed Drinks)",
    "Tequila (or Tequila Mixed Drinks)": "Tequila (or Tequila Mixed Drinks)",
    "A premium liquor": "A premium liquor",
    "Budget/cheap liquor": "Budget/cheap liquor",
    "Hard Cider or Lemonade": "Hard Cider or Lemonade",
    "Hard Seltzer": "Hard Seltzer",
    "I don’t drink alcohol": "I don’t drink alcohol",

    // profiling.alcoholpartner
    "What beer, wine or alcohol brands could you see {artist} partnering with?":
      "What beer, wine or alcohol brands could you see {artist} partnering with?",
    "Please tell us a partner brand": "Please tell us a partner brand",

    /* profilecategory.clothing */
    // profiling.buyclothing
    "Which of these do you regularly buy clothes/accessories from?":
      "Which of these do you regularly buy clothes/accessories from?",
    "Discount clothing stores": "Discount clothing stores",
    "Mainstream mall clothing stores": "Mainstream mall clothing stores",
    "High end fashion stores": "High end fashion stores",
    "The online versions of mainstream mall stores":
      "The online versions of mainstream mall stores",
    "A store like WalMart or Target": "A store like WalMart or Target",
    "Online-only stores": "Online-only stores",
    "Online subscription services": "Online subscription services",

    // profiling.artistclothing
    "What clothing, accessories or cosmetics brands would be a good fit for {artist}? This could be any brands you love or ones you associate with {artist}":
      "What clothing, accessories or cosmetics brands would be a good fit for {artist}? This could be any brands you love or ones you associate with {artist}",
    "Please enter a response": "Please enter a response",

    // profiling.beauty
    "Which of these are true for you in deciding on personal care and beauty products (like cosmetics, fragrances/cologne, teeth whitening, hair styling, shaving)?":
      "Which of these are true for you in deciding on personal care and beauty products (like cosmetics, fragrances/cologne, teeth whitening, hair styling, shaving)?",
    "I usually look for the most economical option":
      "I usually look for the most economical option",
    "I prefer to buy products from premium brands":
      "I prefer to buy products from premium brands",
    "The company’s values are an important factor in my decision":
      "The company’s values are an important factor in my decision",
    "I prefer cosmetics/personal care products that are natural or organic":
      "I prefer cosmetics/personal care products that are natural or organic",
    "I use a subscription service (like Dollar Shave Club or Birchbox)":
      "I use a subscription service (like Dollar Shave Club or Birchbox)",

    /* profilecategory.vehicle */
    // profiling.nextvehicle
    "Which types of vehicle would you consider getting next?":
      "Which types of vehicle would you consider getting next?",
    Electric: "Electric",
    Hybrid: "Hybrid",
    SUV: "SUV",
    "Pickup Truck": "Pickup Truck",
    "Small Car": "Small Car",
    Hatchback: "Hatchback",
    Motorcycle: "Motorcycle",

    // profiling.nextvehiclecost
    "Which of these are likely to describe your next vehicle?":
      "Which of these are likely to describe your next vehicle?",
    "Budget/inexpensive": "Budget/inexpensive",
    "Full-Featured/High-End Trim": "Full-Featured/High-End Trim",
    Used: "Used",
    New: "New",
    American: "American",
    Import: "Import",
    "I don’t drive": "I don’t drive",

    // profiling.vehiclepartner
    "What car brand would make a good tour partner?":
      "What car brand would make a good tour partner?",
    Acura: "Acura",
    Alfa: "Alfa",
    "Aston Martin": "Aston Martin",
    Audi: "Audi",
    Bentley: "Bentley",
    BMW: "BMW",
    Buick: "Buick",
    Cadillac: "Cadillac",
    "Chevy/Chevrolet": "Chevy/Chevrolet",
    Chrysler: "Chrysler",
    Dodge: "Dodge",
    Ferrari: "Ferrari",
    FIAT: "FIAT",
    Ford: "Ford",
    Genesis: "Genesis",
    GMC: "GMC",
    Honda: "Honda",
    HUMMER: "HUMMER",
    Hyundai: "Hyundai",
    INFINITI: "INFINITI",
    Isuzu: "Isuzu",
    Jaguar: "Jaguar",
    Jeep: "Jeep",
    Kia: "Kia",
    Lamborghini: "Lamborghini",
    "Land Rover": "Land Rover",
    Lexus: "Lexus",
    Lincoln: "Lincoln",
    Maserati: "Maserati",
    MAZDA: "MAZDA",
    "Mercedes-Benz": "Mercedes-Benz",
    Mercury: "Mercury",
    MINI: "MINI",
    Mitsubishi: "Mitsubishi",
    NISSAN: "NISSAN",
    Porsche: "Porsche",
    RAM: "RAM",
    "Rolls-Royce": "Rolls-Royce",
    Saab: "Saab",
    Saturn: "Saturn",
    Scion: "Scion",
    smart: "smart",
    Subaru: "Subaru",
    Suzuki: "Suzuki",
    Tesla: "Tesla",
    Toyota: "Toyota",
    Volkswagen: "Volkswagen",
    Volvo: "Volvo",

    /* profilecategory.entertainment */
    // profiling.sparetime
    "Which of these are things you like to do in your spare time?":
      "Which of these are things you like to do in your spare time?",
    "Playing team sports (basketball/soccer/softball, etc.)":
      "Playing team sports (basketball/soccer/softball, etc.)",
    "Going to a bar": "Going to a bar",
    "Checking social media": "Checking social media",
    Golf: "Golf",
    "Listening to music": "Listening to music",
    "Playing or creating music (guitar, piano, sequencing, etc.)":
      "Playing or creating music (guitar, piano, sequencing, etc.)",
    "Gaming (console or computer)": "Gaming (console or computer)",
    "Camping or Fishing": "Camping or Fishing",
    "Crafts/Sewing/crocheting": "Crafts/Sewing/crocheting",
    Astrology: "Astrology",
    "Volunteer/charity work": "Volunteer/charity work",
    "Creating social media content": "Creating social media content",
    Reading: "Reading",
    "Work on my car/car tuning": "Work on my car/car tuning",
    "Off-roading/overlanding": "Off-roading/overlanding",
    Traveling: "Traveling",
    "Home improvement": "Home improvement",
    Yoga: "Yoga",
    "Cooking/baking": "Cooking/baking",
    "Church/church activities": "Church/church activities",
    Biking: "Biking",
    "Going to a concert": "Going to a concert",
    "Watching TV/movies/streaming content at home":
      "Watching TV/movies/streaming content at home",
    "Going to the movies": "Going to the movies",
    "Running/doing cardio workouts": "Running/doing cardio workouts",
    "Lifting weights": "Lifting weights",
    "Listen to podcasts": "Listen to podcasts",
    Hiking: "Hiking",
    "Watch sports": "Watch sports",
    Photography: "Photography",
    "None of the above": "None of the above",

    // profiling.hobbybrands
    "What brands related to your hobbies do you think would be a good sponsor for {artist}? Please share any favorite stores or brands that you think would be a fit!":
      "What brands related to your hobbies do you think would be a good sponsor for {artist}? Please share any favorite stores or brands that you think would be a fit!",
    "Please provide a response": "Please provide a response",

    // profiling.watchproviders
    "You sit down to watch something, which are you likely to be watching?":
      "You sit down to watch something, which are you likely to be watching?",
    "Traditional TV/cable": "Traditional TV/cable",
    "Streaming Services": "Streaming Services",
    YouTube: "YouTube",
    "Short Form Video": "Short Form Video",
    "Twitch or live video platform": "Twitch or live video platform",

    // profiling.watchtech
    "Which of the following do you have?":
      "Which of the following do you have?",
    "A piece of “smart” gym equipment": "A piece of “smart” gym equipment",
    "A crypto wallet": "A crypto wallet",
    "A VR headset": "A VR headset",
    "“Wearable” Tech": "“Wearable” Tech",
    "“Smart Home” system": "“Smart Home” system",
    "Smart Speaker": "Smart Speaker",
    "Newest generation console": "Newest generation console",
  },
};
