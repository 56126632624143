import { useSurveyContext } from "Components";
import { useEffect, useMemo, useState } from "react";

export const hueFromHex = (color: string) => {
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);
  r /= 255;
  g /= 255;
  b /= 255;
  let v = Math.max(r, g, b),
    c = v - Math.min(r, g, b);
  let h =
    c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c);
  return 60 * (h < 0 ? h + 6 : h);
};

export const useThemeMode = (): {
  fontColor: string;
  useGradient: boolean;
  mode: "dark" | "light";
} => {
  // survey might be undefined here (ie on the Homepage)
  const { survey } = useSurveyContext();

  let luminance = 100;

  if (survey?.config.backgroundColor) {
    luminance = relativeLuminance(colorToRgb(survey.config.backgroundColor));
  }

  const fontColor = useMemo(() => {
    return !!survey?.config.backgroundImage || luminance <= 80
      ? "#fff"
      : "#999";
  }, [survey?.config]);

  const useGradient = useMemo(() => {
    return (
      !!survey?.config.backgroundImage || (luminance > 40 && luminance <= 70)
    );
  }, [survey?.config]);

  const mode = useMemo(() => {
    return !!survey?.config.backgroundImage || luminance <= 80
      ? "dark"
      : "light";
  }, [survey?.config]);

  return { fontColor, useGradient, mode };
};

export const useActionColor = (): {
  color: string;
  complement: "#333" | "#fff";
} => {
  const [complement, setComplement] = useState<"#333" | "#fff">("#333");
  const mainColor = getComputedStyle(document.documentElement).getPropertyValue(
    "--main-color",
  );

  useEffect(() => {
    if (mainColor) {
      const compl = getTextComplement(mainColor);
      setComplement(compl);
    }
  }, [mainColor]);

  return { color: mainColor, complement };
};

export function getTextComplement(color: string): "#333" | "#fff" {
  const lum = relativeLuminance(colorToRgb(color));
  return lum > 50 ? "#333" : "#fff";
}

function colorToRgb(color: string) {
  if (!color) return;
  if (color.toLowerCase() === "transparent") return [0, 0, 0, 0];
  if (color[0] === "#") {
    if (color.length < 7) {
      // convert #RGB and #RGBA to #RRGGBB and #RRGGBBAA
      color =
        "#" +
        color[1] +
        color[1] +
        color[2] +
        color[2] +
        color[3] +
        color[3] +
        (color.length > 4 ? color[4] + color[4] : "");
    }
    return [
      parseInt(color.substr(1, 2), 16),
      parseInt(color.substr(3, 2), 16),
      parseInt(color.substr(5, 2), 16),
      color.length > 7 ? parseInt(color.substr(7, 2), 16) / 255 : 1,
    ];
  }
  if (color.indexOf("rgb") === -1) {
    // convert named colors
    var temp_elem = document.body.appendChild(document.createElement("fictum")); // intentionally use unknown tag to lower chances of css rule override with !important
    var flag = "rgb(1, 2, 3)"; // this flag tested on chrome 59, ff 53, ie9, ie10, ie11, edge 14
    temp_elem.style.color = flag;
    if (temp_elem.style.color !== flag) return; // color set failed - some monstrous css rule is probably taking over the color of our object
    temp_elem.style.color = color;
    if (temp_elem.style.color === flag || temp_elem.style.color === "") return; // color parse failed
    color = getComputedStyle(temp_elem).color;
    document.body.removeChild(temp_elem);
  }
  if (color.indexOf("rgb") === 0) {
    if (color.indexOf("rgba") === -1) color += ",1"; // convert 'rgb(R,G,B)' to 'rgb(R,G,B)A' which looks awful but will pass the regxep below
    return color.match(/[.\d]+/g).map(function (a) {
      return +a;
    });
  }
}

// red, green, and blue coefficients
const rc = 0.2126;
const gc = 0.7152;
const bc = 0.0722;
// low-gamma adjust coefficient
const lowc = 1 / 12.92;

function adjustGamma(_: number) {
  return Math.pow((_ + 0.055) / 1.055, 2.4);
}

/**
 * Given a 3-element array of R, G, B varying from 0 to 255, return the luminance
 * as a number from 0 to 1.
 * @param {Array<number>} rgb 3-element array of a color
 * @returns {number} luminance, between 0 and 1
 * @example
 * var luminance = require('relative-luminance');
 * var black_lum = luminance([0, 0, 0]); // 0
 */
function relativeLuminance(rgb: number[]): number {
  const rsrgb = rgb[0] / 255;
  const gsrgb = rgb[1] / 255;
  const bsrgb = rgb[2] / 255;

  const rN = rsrgb <= 0.03928 ? rsrgb * lowc : adjustGamma(rsrgb);
  const gN = gsrgb <= 0.03928 ? gsrgb * lowc : adjustGamma(gsrgb);
  const bN = bsrgb <= 0.03928 ? bsrgb * lowc : adjustGamma(bsrgb);

  return (rN * rc + gN * gc + bN * bc) * 100;
}
