import { SurveyConfigModule } from "@max/common/setfan";
import { useClickOutside } from "Components/utils";
import { useActionColor } from "../../color";
import { Icon } from "melodies-source/Icon";
import { Fragment, useRef, useState } from "react";
import styled, { css } from "styled-components";

const Dropdown = (
  props: SurveyConfigModule & {
    handleChange: (val: string) => void;
    value: string;
    placeholder?: string;
  },
) => {
  const [isOpen, setIsOpen] = useState(false);

  const optionsListRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useClickOutside(optionsListRef, () => setIsOpen(false), dropdownMenuRef);

  const { color: actionColor, complement: selectedTextColor } =
    useActionColor();

  return (
    <Container>
      <DropdownContainer
        ref={dropdownMenuRef}
        onClick={() => setIsOpen((open) => !open)}
        tabIndex={0}
        style={{ marginTop: 10 }}
        onKeyDown={({ currentTarget, target, code }) => {
          if (currentTarget !== target) return;
          if (code === "Space" || code === "Enter") setIsOpen(true);
          if (code === "Escape") setIsOpen(false);
        }}
      >
        {props.placeholder && !props.value && (
          <PlaceholderText>{props.placeholder}</PlaceholderText>
        )}
        {props.value && (
          <DropdownLabel>
            {props.options.find((opt) => opt.id === props.value)?.label}
          </DropdownLabel>
        )}
        <StyledIconContainer isOpen={isOpen}>
          <Icon type="down" />
        </StyledIconContainer>
      </DropdownContainer>
      <OptionsList
        ref={optionsListRef}
        $isOpen={isOpen}
        $overflow={(props.options?.length ?? 0) * 36 > 250}
      >
        {props.options?.map((opt, idx) => (
          <Fragment key={opt.id}>
            <ListItem
              tabIndex={0}
              onClick={() => {
                props.handleChange(opt.id);
                setIsOpen(false);
              }}
              isSelected={props.value === opt.id}
              selectedTextColor={selectedTextColor}
              actionColor={actionColor}
              onKeyDown={({ code }) => {
                if (code === "Space" || code === "Enter") {
                  props.handleChange(opt.id);
                  setIsOpen(false);
                }
                if (code === "Escape") {
                  setIsOpen(false);
                }
              }}
            >
              {opt.label}
            </ListItem>
            {idx !== props.options.length - 1 && <DividerLine />}
          </Fragment>
        ))}
      </OptionsList>
    </Container>
  );
};

export default Dropdown;

const Container = styled.div`
  position: relative;
`;

const DividerLine = styled.div`
  height: 1px;
  border-bottom: thin solid #ddd;
  width: calc(100% + 22px);
  margin-left: -11px;
`;

const DropdownLabel = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: Poppins;
  color: #333333;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionsList = styled.div<{ $isOpen: boolean; $overflow: boolean }>`
  margin-top: 5px;
  position: absolute;
  width: 100%;
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px #f9fafb;
  border-radius: 6px;
  overflow-y: ${({ $overflow }) => ($overflow ? "scroll" : "hidden")};
  overflow-x: hidden;
  max-height: ${({ $isOpen }) => ($isOpen ? "250px" : "0px")};
  height: ${({ $isOpen }) => ($isOpen ? "250px" : "0px")};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  z-index: 10;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  transition: all 0.15s;
`;

const DropdownContainer = styled.div<{
  hasError?: boolean;
  disabled?: boolean;
}>`
  outline: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  height: 44px;

  background: #ffffff;
  border: solid 1px #cccccc;
  border-radius: 6px;
  padding: 11px 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);

  z-index: 1;
  position: relative;

  :focus {
    box-shadow: 0px 0px 8px var(--max-action);
    border: solid 1px var(--max-action);
  }

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: #999999;
      border: solid 1px #e6e9eb;
    `};

  ${(p) =>
    p.hasError &&
    css`
      border: solid 2px #f5a5b1;
    `};
`;

const StyledIconContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 10px;
  right: 8px;
  transform: ${({ isOpen }) => (isOpen ? "rotate(-180deg)" : "rotate(0)")};
  transition: all 0.2s;
`;

const ListItem = styled.div<{
  label?: string;
  value?: string;
  isSelected?: boolean;
  selectedTextColor?: string;
  actionColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ isSelected, selectedTextColor }) =>
    isSelected && `background: var(--max-action); color: ${selectedTextColor};`}

  :hover {
    cursor: pointer;
    background: ${({ actionColor }) => `${actionColor}99`};
    color: ${({ selectedTextColor }) => selectedTextColor};
  }

  padding: 10px;
`;

const PlaceholderText = styled.div`
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  color: #999999;
`;
