import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en, es, fr } from "./translations";

i18n
  // defaults here https://github.com/i18next/i18next-browser-languageDetector/blob/9efebe6ca0271c3797bc09b84babf1ba2d9b4dbb/src/index.js#L11
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    keySeparator: ".",
    saveMissing: true,
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    missingKeyHandler: () => {},
    parseMissingKeyHandler: (key) => `**** ${key} ****`,
    resources: {
      en: { ...en },
      es: { ...es },
      fr: { ...fr },
    },
  });

export default i18n;
