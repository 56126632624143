import styled from "styled-components";
import SetLiveLogo from "./SVGs/SetLiveLogo";
import { useThemeMode } from "../color";

export const Header = () => {
  const { useGradient } = useThemeMode();

  return (
    <FullWidth useGradient={useGradient}>
      <HeaderContainer>
        <StyledLogo />
      </HeaderContainer>
    </FullWidth>
  );
};

const FullWidth = styled.div<{ useGradient: boolean }>`
  ${({ useGradient }) =>
    useGradient &&
    "background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);"}
  margin: 0 -33px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0 -21px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  margin: 0 33px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0 21px;
  }
`;

const StyledLogo = styled(SetLiveLogo)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 38px;
    height: 19px;
  }
`;
