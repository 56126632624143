import { Button } from "melodies-source/Button";
import styled from "styled-components";
import {
  useDataContext,
  useRule,
  validateModule,
  useAnalytics,
  useSurveyContext,
} from "Components";
import { useActionColor } from "../../color";
import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse, SurveyResponses } from "@max/common/functions/setfan";
import { useTranslation } from "react-i18next";

export const SubmitButton = ({
  style,
  content,
  enable,
  parent,
  href,
  id,
  children,
  ...props
}: SurveyConfigModule) => {
  const { complement: textColor } = useActionColor();
  const { t } = useTranslation();

  const { id: surveyId, survey } = useSurveyContext();
  const {
    data,
    setData,
    onSubmit,
    view,
    setView,
    showValidation,
    setShowValidation,
    isSubmissionPage,
  } = useDataContext();
  const { logClick } = useAnalytics();
  const d = data?.[id];
  const enabledRule = useRule(enable);
  const enabled = enable
    ? enabledRule
    : validateModule(parent, data, surveyId, survey.localProfiling);

  const handleClick = () => {
    // we use this component for custom links as well. If an href is present,
    // we do not want to run any other submit handler logic
    if (href) {
      setTimeout(() => {
        window.location.href = href;
      }, 50);
      logClick({
        action: "featuredCta",
        label: id,
        url: href,
      });
      return;
    }

    if (!enabled) {
      setShowValidation(true);
      return;
    }

    setShowValidation(false);

    logClick({
      action: "submit",
      label: id,
      view,
    });

    let update: SurveyResponses = {
      ...data,
      [id]: {
        ...d,
        value: true,
      },
    };

    if (isSubmissionPage) {
      update = {
        ...update,
        // why did you make completeSurveys a different type than the rest of the responses :(
        completeSurveys: [
          ...((update.completeSurveys as string[]) || []),
          surveyId,
        ] as SurveyResponse,
      };
      onSubmit(update);
    }

    setData(update);

    setView(view + 1);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {!enabled && showValidation && (
        <ValidationError>
          {t(
            "Please correct the missing or incomplete information above and hit submit again",
          )}
        </ValidationError>
      )}
      <ButtonAdjusted
        textColor={textColor}
        style={style}
        onClick={handleClick}
        {...props}
        // if a featuredCta or something similar and a label (children) exists, use it.
        // else, use the previous logic based on page.
        // NOTE: We have a bit of an edge case for use in the preview. The preview app
        // might have an empty string for the href and children even with the `Feature CTA` checkbox
        // selected. So we need to check the type of href and children instead of just whether or not
        // they are truthy so we can handle the edge case of empty href and empty children but still
        // needing the button to display.
        children={
          typeof href === "string" && typeof children === "string"
            ? children
            : isSubmissionPage
              ? t("Submit")
              : t("Continue")
        }
        type="button"
        variant="primary"
      />
    </>
  );
};

const ButtonAdjusted = styled(Button)<{ textColor: string }>`
  width: auto;
  background-color: var(--max-action);
  color: ${({ textColor }) => textColor};

  :hover {
    background-color: var(--max-action);
    opacity: 0.9;
  }
`;

const ValidationError = styled.div`
  background: #e3463b;
  padding: 10px;
  margin-bottom: 31px;
  border-radius: 6px;
  color: #fff;
`;
