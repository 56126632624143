import { Module } from "Components";
import styled from "styled-components";
import { Card as CardComponent } from "melodies-source/Card";
import { SurveyConfigModule } from "@max/common/setfan";
import { CardVariant } from "@max/common/functions/setfan";
import { useId } from "react";

export const Card = (parent: SurveyConfigModule) => {
  const id = useId();

  return (
    <VariantCard
      variant={parent.variant as "body" | "header" | "merch"}
      props={parent}
    >
      <>
        {parent.modules.map((mod) => (
          <Module {...mod} parent={parent} key={mod.id || id} />
        ))}
      </>
    </VariantCard>
  );
};

const VariantCard = ({
  variant,
  props,
  children,
}: {
  variant: CardVariant;
  props: SurveyConfigModule;
  children: JSX.Element;
}) => {
  // handle old surveys without card variants
  if (!variant) {
    variant = !!props.image ? "header" : "body";
  }

  switch (variant) {
    case "body":
      return (
        <BodyCard backgroundColor={props.backgroundColor} style={props.style}>
          {children}
        </BodyCard>
      );
    case "header":
      return (
        <HeaderCard
          backgroundColor={props.backgroundColor}
          imageAlign={props.image?.align}
          compact={props.compact}
          style={props.style}
        >
          <>
            {!!props.image && (
              <RoundedImage compact={props.compact} style={props.image.style}>
                <HeaderImage src={props.image.src} alt="Header Image" />
              </RoundedImage>
            )}
            {children}
          </>
        </HeaderCard>
      );
    case "merch":
      return (
        <MerchCard style={props.style}>
          <div
            onClick={() => window.open(props.url)}
            style={{ cursor: "pointer" }}
          >
            <MerchImageContainer>
              <HeaderImage src={props.image.src} alt="Merchandise" />
            </MerchImageContainer>
            {children}
          </div>
        </MerchCard>
      );
  }
};

const BaseCard = styled(CardComponent)<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ backgroundColor }) => backgroundColor || "white"};
`;

const BodyCard = styled(BaseCard)`
  border: 2px solid #cdcece;
  border-radius: 20px;

  ${({ theme }) => theme.mediaQueries.desktop} {
    padding: 42px 52px 42px 52px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20px;
  }
`;

const HeaderCard = styled(BaseCard)<{
  imageAlign?: "left" | "center";
  compact: boolean;
}>`
  border: 2px solid #cdcece;
  border-radius: 20px;

  ${({ imageAlign }) => imageAlign === "center" && "align-items: center;"}

  ${({ theme }) => theme.mediaQueries.desktop} {
    min-height: 88px;
    min-width: calc(100% - 42px);

    padding: ${({ compact }) =>
      compact ? "17px 125px 17px 117px" : "70px 50px 50px 50px"};

    ${({ compact }) => compact && "justify-content: center; margin-left: 42px;"}
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    min-height: 63px;
    min-width: calc(100% - 25px);

    padding: ${({ compact }) =>
      compact ? "0 20px 0 45px" : "60px 20px 20px 20px"};

    ${({ compact }) => compact && "justify-content: center; margin-left: 25px;"}
  }
`;

const MerchCard = styled(BaseCard).attrs(() => ({
  backgroundColor:
    "linear-gradient(90deg, #ECECEC 13.29%, rgba(221, 221, 221, 0) 86.01%);",
}))`
  height: 80px;
  width: 100%;
  border-radius: 9px;
  border: 1px solid #dcdcdc;
  margin-bottom: 14px;
  justify-content: center;

  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.desktop} {
    padding: 30px 20px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 23px 16px;
  }
`;

const MerchImageContainer = styled.div`
  position: absolute;
  transform: rotate(27.64deg);
  right: 0;
  width: 84px;
  height: 84px;
  bottom: 0px;
`;

const RoundedImage = styled.div<{ compact?: boolean }>`
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  border: 2px solid #565858;

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${({ compact }) =>
      compact &&
      `
        width: 63px;
        height: 63px;
        top: -2px;
        left: -30px;
      `}

    ${({ compact }) =>
      !compact &&
      `
        width: 99px;
        height: 99px;
        top: -49px;
      `}
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    ${({ compact }) =>
      compact &&
      `
        width: 88px;
        height: 88px;
        left: -44px;
      `}

    ${({ compact }) =>
      !compact &&
      `
        width: 145px;
        height: 145px;
        top: -85px;
      `}
  }
`;

const HeaderImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
