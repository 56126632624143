import { FC, createContext, useEffect, useContext } from "react";
import TagManager from "react-gtm-module";
import { useAuth } from "./Auth";

interface EventParams {
  label?: string;
  url?: string;
  view?: number;
}

interface LogClickParams extends EventParams {
  action: string;
}

interface IContext {
  logClick: (logClickParams: LogClickParams) => void;
}

type Props = {
  children?: React.ReactNode;
};

export const AnalyticsContext = createContext<IContext>(null);

export const AnalyticsProviderReal: FC<Props> = ({ children }) => {
  const { user } = useAuth();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      setUserId(user.uid);
    }
  }, [user]);

  const setUserId = (uid: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "set_user_id",
        user_id: uid,
      },
    });
  };

  const logClick = ({ view, action, label }: LogClickParams) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "button_click",
        action,
        label,
        view,
      },
    });
  };

  const providerValue: IContext = {
    logClick,
  };

  return (
    <AnalyticsContext.Provider value={providerValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const AnalyticsProviderMock: FC<Props> = ({ children }) => {
  function logClick(params: LogClickParams) {}
  const providerValue: IContext = {
    logClick,
  };
  return (
    <AnalyticsContext.Provider value={providerValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsProvider =
  process.env.REACT_APP_PREVIEW_MODE === "true"
    ? AnalyticsProviderMock
    : AnalyticsProviderReal;

export const useAnalytics = () => useContext(AnalyticsContext);
