import { DefaultProfiling } from "@max/common";
import { default as i18n } from "i18n";

export const defaultProfiling: DefaultProfiling = [
  [
    {
      id: "profilecategory.email",
      questions: [
        {
          type: "EmailQuestion",
          id: "email",
          header: { children: i18n.t("Enter your Email") },
          validation: {
            type: "email",
            message: i18n.t("Please enter your email"),
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.core",
      questions: [
        {
          type: "NameQuestion",
          id: "name",
          header: { children: i18n.t("Please enter your name") },
          validation: {
            type: "name",
            message: i18n.t("Please enter your name"),
          },
        },
        {
          type: "PhoneQuestion",
          id: "phone",
          header: { children: i18n.t("Your Phone") },
          validation: {
            type: "required",
            message: i18n.t("Please enter your phone"),
          },
        },
        {
          type: "ZipQuestion",
          id: "zip",
          validation: {
            type: "zip",
            message: i18n.t("Please enter your zip code"),
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.demographics",
      questions: [
        {
          type: "YearBorn",
          id: "profiling.yearborn",
          header: {
            children: i18n.t("What year were you born?"),
          },
          validation: {
            type: "yearborn",
            message: i18n.t("Enter year as YYYY format (e.g. 1987)"),
          },
        },
        {
          type: "SingleSelectQuestion",
          id: "profiling.gender",
          header: {
            children: i18n.t("Are you?"),
          },
          options: [
            { id: "female", label: i18n.t("Female") },
            { id: "male", label: i18n.t("Male") },
            { id: "nonbinary", label: i18n.t("Non-binary") },
            { id: "notsay", label: i18n.t("Prefer not to say") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.musicsource",
          header: {
            children: i18n.t(
              "How do you most often listen to music (Choose up to 3)?",
            ),
          },
          options: [
            { id: "radio", label: i18n.t("Radio") },
            { id: "vinyl", label: i18n.t("Vinyl/Records") },
            { id: "tapes", label: i18n.t("Tapes") },
            { id: "cd", label: i18n.t("CDs") },
            { id: "amazon", label: i18n.t("Amazon Music Unlimited") },
            { id: "apple", label: i18n.t("Apple Music") },
            { id: "pandorafree", label: i18n.t("Pandora (Free)") },
            { id: "pandorapaid", label: i18n.t("Pandora (Paid Subscription)") },
            { id: "spotifyfree", label: i18n.t("Spotify (Free)") },
            { id: "spotifypaid", label: i18n.t("Spotify (Paid Subscription)") },
            { id: "tidal", label: i18n.t("Tidal") },
            { id: "yt", label: i18n.t("YouTube (The Main Website/App)") },
            { id: "ytm", label: i18n.t("YouTube (Music Streaming Service)") },
            { id: "none", label: i18n.t("None of these") },
          ],
          validation: {
            type: "require_count",
            min: 1,
            max: 3,
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.socialusage",
          header: {
            children: i18n.t("Which of these do you use daily?"),
          },
          options: [
            { id: "bereal", label: i18n.t("BeReal") },
            { id: "discord", label: i18n.t("Discord") },
            { id: "facebook", label: i18n.t("Facebook") },
            { id: "instagram", label: i18n.t("Instagram") },
            { id: "snapchat", label: i18n.t("Snapchat") },
            { id: "tiktok", label: i18n.t("TikTok") },
            { id: "twitch", label: i18n.t("Twitch") },
            { id: "twitter", label: i18n.t("Twitter") },
            { id: "none", label: i18n.t("None of these") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.fooddrink",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.eaten",
          header: {
            children: i18n.t(
              "Which of the following have you eaten in the last week?",
            ),
          },
          options: [
            {
              id: "casual",
              label: i18n.t(
                "A meal from a casual sit-down restaurant (dine-in or takeout)",
              ),
            },
            {
              id: "fine",
              label: i18n.t(
                "A meal from a fine dining restaurant (dine-in or takeout)",
              ),
            },
            {
              id: "delivery",
              label: i18n.t(
                "Restaurant food delivered via a service like UberEats",
              ),
            },
            { id: "mealkit", label: i18n.t("A meal kit/service I bought") },
            {
              id: "fastfood",
              label: i18n.t(
                "A meal from a fast food restaurant (dine-in, drive-through or takeout",
              ),
            },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.groceries",
          header: {
            children: i18n.t(
              "When you’re getting groceries which are important to you?",
            ),
          },
          options: [
            {
              id: "craving",
              label: i18n.t("What I’m craving"),
            },
            {
              id: "healthy",
              label: i18n.t("How healthy it is"),
            },
            {
              id: "price",
              label: i18n.t("What's on sale/affordable"),
            },
            { id: "local", label: i18n.t("What is local or organic") },
            { id: "deliver", label: i18n.t("What can be delivered") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.drinks",
          header: {
            children: i18n.t("Which of these do you drink on a regular basis?"),
          },
          options: [
            {
              id: "dietsoft",
              label: i18n.t("Diet soft drinks"),
            },
            {
              id: "water",
              label: i18n.t("Bottled/flavored water"),
            },
            {
              id: "tea",
              label: i18n.t("Tea"),
            },
            { id: "juice", label: i18n.t("Fruit or vegetable juice") },
            { id: "sports", label: i18n.t("Sports drinks") },
            { id: "soft", label: i18n.t("Soft drinks (non-diet)") },
            { id: "coffee", label: i18n.t("Coffee/coffee drinks") },
            { id: "energy", label: i18n.t("Energy drinks") },
            { id: "none", label: i18n.t("None of these") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.foodpartner",
          header: {
            children: i18n.t(
              "What food/drink brand or restaurant do you think would be a good partner for an upcoming tour?",
            ),
          },
          validation: {
            type: "required",
            message: i18n.t("Please tell us a partner brand"),
          },
        },
      ],
    },
    {
      id: "profilecategory.alcohol",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.alcoholchoice",
          header: {
            children: i18n.t(
              "When you’re drinking, which of these do you tend to drink (check all that apply)?",
            ),
          },
          options: [
            {
              id: "beer",
              label: i18n.t("Regular (not light, not craft/import) beer"),
            },
            {
              id: "lightbeer",
              label: i18n.t("Light beer"),
            },
            {
              id: "importedbeer",
              label: i18n.t("Imported or Craft Beer"),
            },
            { id: "wine", label: i18n.t("Wine") },
            { id: "vodka", label: i18n.t("Vodka (or Vodka Mixed Drinks)") },
            {
              id: "whiskey",
              label: i18n.t(
                "Whiskey/Bourbon (or Whiskey/Bourbon Mixed Drinks)",
              ),
            },
            { id: "rum", label: i18n.t("Rum (or Rum Mixed Drinks)") },
            { id: "gin", label: i18n.t("Gin (or Gin Mixed Drinks)") },
            {
              id: "tequila",
              label: i18n.t("Tequila (or Tequila Mixed Drinks)"),
            },
            { id: "premiumliquor", label: i18n.t("A premium liquor") },
            { id: "cheapliquor", label: i18n.t("Budget/cheap liquor") },
            { id: "cider", label: i18n.t("Hard Cider or Lemonade") },
            { id: "seltzer", label: i18n.t("Hard Seltzer") },
            { id: "none", label: i18n.t("I don’t drink alcohol") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          id: "profiling.alcoholpartner",
          type: "TextQuestion",
          header: {
            children: i18n.t(
              "What beer, wine or alcohol brands could you see {artist} partnering with?",
            ),
          },
          validation: {
            type: "required",
            message: i18n.t("Please tell us a partner brand"),
          },
        },
      ],
    },
    {
      id: "profilecategory.clothing",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.buyclothing",
          header: {
            children: i18n.t(
              "Which of these do you regularly buy clothes/accessories from?",
            ),
          },
          options: [
            {
              id: "discount",
              label: i18n.t("Discount clothing stores"),
            },
            {
              id: "mainstream",
              label: i18n.t("Mainstream mall clothing stores"),
            },
            {
              id: "highend",
              label: i18n.t("High end fashion stores"),
            },
            {
              id: "onlinemainstream",
              label: i18n.t("The online versions of mainstream mall stores"),
            },
            { id: "walmart", label: i18n.t("A store like WalMart or Target") },
            {
              id: "online",
              label: i18n.t("Online-only stores"),
            },
            {
              id: "subscription",
              label: i18n.t("Online subscription services"),
            },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.artistclothing",
          header: {
            children: i18n.t(
              "What clothing, accessories or cosmetics brands would be a good fit for {artist}? This could be any brands you love or ones you associate with {artist}",
            ),
          },
          validation: {
            type: "required",
            message: i18n.t("Please enter a response"),
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.beauty",
          header: {
            children: i18n.t(
              "Which of these are true for you in deciding on personal care and beauty products (like cosmetics, fragrances/cologne, teeth whitening, hair styling, shaving)?",
            ),
          },
          options: [
            {
              id: "economical",
              label: i18n.t("I usually look for the most economical option"),
            },
            {
              id: "premium",
              label: i18n.t("I prefer to buy products from premium brands"),
            },
            {
              id: "ethics",
              label: i18n.t(
                "The company’s values are an important factor in my decision",
              ),
            },
            {
              id: "natural",
              label: i18n.t(
                "I prefer cosmetics/personal care products that are natural or organic",
              ),
            },
            {
              id: "subscription",
              label: i18n.t(
                "I use a subscription service (like Dollar Shave Club or Birchbox)",
              ),
            },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
      ],
    },
    {
      id: "profilecategory.vehicle",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.nextvehicle",
          header: {
            children: i18n.t(
              "Which types of vehicle would you consider getting next?",
            ),
          },
          options: [
            { id: "electric", label: i18n.t("Electric") },
            { id: "hybrid", label: i18n.t("Hybrid") },
            { id: "suv", label: i18n.t("SUV") },
            { id: "pickup", label: i18n.t("Pickup Truck") },
            { id: "car", label: i18n.t("Small Car") },
            { id: "hatchback", label: i18n.t("Hatchback") },
            { id: "motorcycle", label: i18n.t("Motorcycle") },
            { id: "none", label: i18n.t("I don’t drive") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.nextvehiclecost",
          header: {
            children: i18n.t(
              "Which of these are likely to describe your next vehicle?",
            ),
          },
          options: [
            { id: "budget", label: i18n.t("Budget/inexpensive") },
            { id: "highend", label: i18n.t("Full-Featured/High-End Trim") },
            { id: "used", label: i18n.t("Used") },
            { id: "new", label: i18n.t("New") },
            { id: "american", label: i18n.t("American") },
            { id: "Import", label: i18n.t("Import") },
            { id: "none", label: i18n.t("I don’t drive") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          id: "profiling.vehiclepartner",
          type: "Autocomplete",
          options: [
            {
              id: "Acura",
              label: i18n.t("Acura"),
            },
            {
              id: "Alfa",
              label: i18n.t("Alfa"),
            },
            {
              id: "Aston Martin",
              label: i18n.t("Aston Martin"),
            },
            {
              id: "Audi",
              label: i18n.t("Audi"),
            },
            {
              id: "Bentley",
              label: i18n.t("Bentley"),
            },
            {
              id: "BMW",
              label: i18n.t("BMW"),
            },
            {
              id: "Buick",
              label: i18n.t("Buick"),
            },
            {
              id: "Cadillac",
              label: i18n.t("Cadillac"),
            },
            {
              id: "Chevy/Chevrolet",
              label: i18n.t("Chevy/Chevrolet"),
            },
            {
              id: "Chrysler",
              label: i18n.t("Chrysler"),
            },
            {
              id: "Dodge",
              label: i18n.t("Dodge"),
            },
            {
              id: "Ferrari",
              label: i18n.t("Ferrari"),
            },
            {
              id: "FIAT",
              label: i18n.t("FIAT"),
            },
            {
              id: "Ford",
              label: i18n.t("Ford"),
            },
            {
              id: "Genesis",
              label: i18n.t("Genesis"),
            },
            {
              id: "GMC",
              label: i18n.t("GMC"),
            },
            {
              id: "Honda",
              label: i18n.t("Honda"),
            },
            {
              id: "HUMMER",
              label: i18n.t("HUMMER"),
            },
            {
              id: "Hyundai",
              label: i18n.t("Hyundai"),
            },
            {
              id: "INFINITI",
              label: i18n.t("INFINITI"),
            },
            {
              id: "Isuzu",
              label: i18n.t("Isuzu"),
            },
            {
              id: "Jaguar",
              label: i18n.t("Jaguar"),
            },
            {
              id: "Jeep",
              label: i18n.t("Jeep"),
            },
            {
              id: "Kia",
              label: i18n.t("Kia"),
            },
            {
              id: "Lamborghini",
              label: i18n.t("Lamborghini"),
            },
            {
              id: "Land Rover",
              label: i18n.t("Land Rover"),
            },
            {
              id: "Lexus",
              label: i18n.t("Lexus"),
            },
            {
              id: "Lincoln",
              label: i18n.t("Lincoln"),
            },
            {
              id: "Maserati",
              label: i18n.t("Maserati"),
            },
            {
              id: "MAZDA",
              label: i18n.t("MAZDA"),
            },
            {
              id: "Mercedes-Benz",
              label: i18n.t("Mercedes-Benz"),
            },
            {
              id: "Mercury",
              label: i18n.t("Mercury"),
            },
            {
              id: "MINI",
              label: i18n.t("MINI"),
            },
            {
              id: "Mitsubishi",
              label: i18n.t("Mitsubishi"),
            },
            {
              id: "NISSAN",
              label: i18n.t("NISSAN"),
            },
            {
              id: "Porsche",
              label: i18n.t("Porsche"),
            },
            {
              id: "RAM",
              label: i18n.t("RAM"),
            },
            {
              id: "Rolls-Royce",
              label: i18n.t("Rolls-Royce"),
            },
            {
              id: "Saab",
              label: i18n.t("Saab"),
            },
            {
              id: "Saturn",
              label: i18n.t("Saturn"),
            },
            {
              id: "Scion",
              label: i18n.t("Scion"),
            },
            {
              id: "smart",
              label: i18n.t("smart"),
            },
            {
              id: "Subaru",
              label: i18n.t("Subaru"),
            },
            {
              id: "Suzuki",
              label: i18n.t("Suzuki"),
            },
            {
              id: "Tesla",
              label: i18n.t("Tesla"),
            },
            {
              id: "Toyota",
              label: i18n.t("Toyota"),
            },
            {
              id: "Volkswagen",
              label: i18n.t("Volkswagen"),
            },
            {
              id: "Volvo",
              label: i18n.t("Volvo"),
            },
          ],
          header: {
            children: i18n.t("What car brand would make a good tour partner?"),
          },
          validation: {
            type: "required",
            message: i18n.t("Please tell us a partner brand"),
          },
        },
      ],
    },
    {
      id: "profilecategory.entertainment",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.sparetime",
          header: {
            children: i18n.t(
              "Which of these are things you like to do in your spare time?",
            ),
          },
          options: [
            {
              id: "teamsports",
              label: i18n.t(
                "Playing team sports (basketball/soccer/softball, etc.)",
              ),
            },
            { id: "bar", label: i18n.t("Going to a bar") },
            { id: "socialmedia", label: i18n.t("Checking social media") },
            { id: "golf", label: i18n.t("Golf") },
            { id: "music", label: i18n.t("Listening to music") },
            {
              id: "makingmusic",
              label: i18n.t(
                "Playing or creating music (guitar, piano, sequencing, etc.)",
              ),
            },
            { id: "gaming", label: i18n.t("Gaming (console or computer)") },
            { id: "camping", label: i18n.t("Camping or Fishing") },
            { id: "crafts", label: i18n.t("Crafts/Sewing/crocheting") },
            { id: "astrology", label: i18n.t("Astrology") },
            { id: "volunteer", label: i18n.t("Volunteer/charity work") },
            {
              id: "createsocialmedia",
              label: i18n.t("Creating social media content"),
            },
            { id: "reading", label: i18n.t("Reading") },
            { id: "tuning", label: i18n.t("Work on my car/car tuning") },
            { id: "offroad", label: i18n.t("Off-roading/overlanding") },
            { id: "traveling", label: i18n.t("Traveling") },
            { id: "home", label: i18n.t("Home improvement") },
            { id: "yoga", label: i18n.t("Yoga") },
            { id: "cooking", label: i18n.t("Cooking/baking") },
            { id: "church", label: i18n.t("Church/church activities") },
            { id: "biking", label: i18n.t("Biking") },
            { id: "concert", label: i18n.t("Going to a concert") },
            {
              id: "tv",
              label: i18n.t("Watching TV/movies/streaming content at home"),
            },
            { id: "movies", label: i18n.t("Going to the movies") },
            { id: "cardio", label: i18n.t("Running/doing cardio workouts") },
            { id: "weights", label: i18n.t("Lifting weights") },
            { id: "podcast", label: i18n.t("Listen to podcasts") },
            { id: "hiking", label: i18n.t("Hiking") },
            { id: "watchsports", label: i18n.t("Watch sports") },
            { id: "photography", label: i18n.t("Photography") },
            { id: "none", label: i18n.t("None of the above") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.hobbybrands",
          header: {
            children: i18n.t(
              "What brands related to your hobbies do you think would be a good sponsor for {artist}? Please share any favorite stores or brands that you think would be a fit!",
            ),
          },
          validation: {
            type: "required",
            message: i18n.t("Please provide a response"),
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.watchproviders",
          header: {
            children: i18n.t(
              "You sit down to watch something, which are you likely to be watching?",
            ),
          },
          options: [
            { id: "tv", label: i18n.t("Traditional TV/cable") },
            { id: "streaming", label: i18n.t("Streaming Services") },
            { id: "yt", label: i18n.t("YouTube") },
            { id: "shortform", label: i18n.t("Short Form Video") },
            {
              id: "livestream",
              label: i18n.t("Twitch or live video platform"),
            },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.watchtech",
          header: {
            children: i18n.t("Which of the following do you have?"),
          },
          options: [
            {
              id: "peloton",
              label: i18n.t("A piece of “smart” gym equipment"),
            },
            { id: "crypto", label: i18n.t("A crypto wallet") },
            { id: "vr", label: i18n.t("A VR headset") },
            { id: "smartwatch", label: i18n.t("“Wearable” Tech") },
            { id: "smarthome", label: i18n.t("“Smart Home” system") },
            { id: "smartspeaker", label: i18n.t("Smart Speaker") },
            { id: "console", label: i18n.t("Newest generation console") },
            { id: "none", label: i18n.t("None of these") },
          ],
          validation: {
            type: "required",
            message: i18n.t("Please select at least one item"),
          },
        },
      ],
    },
  ],
];
